import React from "react";
import { Link } from "gatsby";

import * as Constants from "../../lib/constants";

import Layout from "../../components/Layout";

import * as styles from "./HelpCenter.module.css";
import { helpCenterItems } from "../../lib/helpCenterItems";

const HelpCenter = ({ data, path }) => {
  const helpCenterItemPaths = data.allSitePage.edges.map((item) => item.node.path);
  return (
    <Layout
      title="Help Center"
      metaDesc="Answers for Frequently Asked Questions for Tobo Apps."
      ogUrlPath="/help-center/"
      canonicalPath="/help-center/"
    >
      <main
        style={{
          padding: "20px 0",
        }}
      >
        <div>
          <h1>Tobo Help Center</h1>
          <ul>
            {helpCenterItemPaths.map((path) => {
              const item = helpCenterItems.find((el) => {
                return path === `/help-center/${el.id}/`;
              });

              if (!item) {
                return null;
              }

              return (
                <li key={item.id} className={styles.listItem}>
                  <Link to={path}>{item.title}</Link>
                </li>
              );
            })}
          </ul>
        </div>
      </main>
    </Layout>
  );
};

export default HelpCenter;
