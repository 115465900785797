import React from "react";
import { graphql } from "gatsby";

import HelpCenterContainer from "../../containers/HelpCenter";

const HelpCenter = ({ data, path }) => {
  return <HelpCenterContainer data={data} path={path} />;
};

export const query = graphql`
  query HelpCenterQuery {
    allSitePage(filter: { path: { regex: "/help-center/.+/" } }) {
      edges {
        node {
          path
        }
      }
    }
  }
`;

export default HelpCenter;
